import axios from "axios"
import { makeLink } from "../helpers/helper.properties"

// constantes
const dataInicial = {
  data: {
    nombre: "",
    apellido: "",
    cellphone: "",
    phone: "",
    email: "",
    work_name: "",
    text: "",
    properties: "",
    tags: "",
  },
}

// types
const LOAD_FIELDS = "LOAD_FIELDS"

// reducer
export default function contactReducer(state = dataInicial, action) {
  switch (action.type) {
    case LOAD_FIELDS:
      return { ...state, data: action.payload }
    default:
      return state
  }
}

// actions

export const newSletterTokko = () => async (dispatch, getState) => {
  const fields = getState().contact.data
  try {
    // Campos del formulario //
    var tags = ["Newsletter", "Sitio Web"]
    const message = JSON.stringify({
      email: fields.email,
      tags: tags,
    })

    await axios.post(
      "https://tokkobroker.com/api/v1/webcontact/?key=" +
        getState().settings.keys.tokko,
      message,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
  } catch (error) {
    console.log(error)
  }
}

export const formTokko =
  (tag, property = null, id = null, isDevelopment = false) =>
  async (dispatch, getState) => {
    const fields = getState().contact.data
    try {
      // Campos del formulario //
      let body = ""
      var name = ""
      var tags = [...tag, "Sitio Web"]
      if (property) {
        tags.push(property)
      }
      if (fields.nombre) {
        name += fields.nombre
      }
      if (fields.apellido) {
        name += " " + fields.apellido
      }
      if (fields.telefono) {
        body += "Teléfono: " + fields.telefono + "\n"
      }
      if (fields.cellphone) {
        body += "Celular: " + fields.cellphone + "\n"
      }
      // if(fields.email){body += "\n Email: " + fields.email}
      // if(tag == 'Landing Web' || tag ==  'Ficha web unidad'){body += "Emprendimiento: " + property + "\n"}
      // if(tag == 'Propiedad' ){body += "Propiedad: " + property + "\n"; }
      body += "Email: " + fields.email + "\n"
      if (fields.text) {
        body += "Mensaje: " + fields.text + "\n"
      }

      var message = ""

      if (isDevelopment) {
        message = JSON.stringify({
          name,
          email: fields.email,
          cellphone: fields.cellphone,
          tags,
          developments: id,
          text: body,
        })
      }
      if (!isDevelopment) {
        message = JSON.stringify({
          name,
          email: fields.email,
          cellphone: fields.cellphone,
          tags,
          properties: id,
          text: body,
        })
      }

      await axios.post(
        "https://tokkobroker.com/api/v1/webcontact/?key=" +
          getState().settings.keys.tokko,
        message,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    } catch (error) {
      console.log(error)
    }
  }

export const formTasaciones_Tokko =
  (type, operation, customs = []) =>
  async (dispatch, getState) => {
    const fields = getState().contact.data
    try {
      // Campos del formulario //
      let body = ""
      var tags = [...customs, "Tasación", "Sitio Web"]
      body += "\n Nombre: " + fields.nombre
      body += "\n Email: " + fields.email
      body += "\n Ubicación: " + fields.ubicacion
      body += "\n Tipo: " + type
      body += "\n Operacion: " + operation
      if (fields.phone) {
        body += "\n Teléfono: " + fields.phone
      }
      if (fields.rooms) {
        body += "\n Ambientes: " + fields.rooms
      }
      if (fields.age) {
        body += "\n Antiguedad: " + fields.age
      }
      if (fields.garage) {
        body += "\n Garage: " + fields.garage
      }
      if (fields.sup_tot) {
        body += "\n Superficie total: " + fields.sup_tot
      }
      if (fields.sup_cub) {
        body += "\n Superficie cubierta: " + fields.sup_cub
      }
      if (fields.amenities.length > 0) {
        body += "\n Amenities: " + fields.amenities
      }

      const message = JSON.stringify({
        name: fields.nombre,
        email: fields.email,
        phone: fields.phone,
        tags,
        text: body,
      })
      console.log(message)
      await axios.post(
        "https://tokkobroker.com/api/v1/webcontact/?key=" +
          getState().settings.keys.tokko,
        message,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
    } catch (error) {
      console.log(error)
    }
  }

export const updateFields = datos => async (dispatch, getState) => {
  try {
    dispatch({
      type: LOAD_FIELDS,
      payload: datos,
    })
  } catch (error) {
    console.log(error)
  }
}

export const send_form_mediacore =
  (type, data, CLIENTID) => async (dispatch, getState) => {
    //
    console.log(data)
    try {
      let data_form = {}
      switch (type) {
        case "contact": {
          data_form = {
            contact: {
              status: true,
              name: data.nombre,
              phone: data.cellphone,
              email: data.email,
              message: data.text,
            },
          }
          break
        }
        case "property": {
          data_form = {
            property_query: {
              status: true,
              name: data.nombre,
              phone: data.cellphone,
              email: data.email,
              message: data.text,
              address: data.property.address,
              property_id: data.property.id,
              url: data.url,
              operation_type: data.property?.operations?.map(
                item => item.operation_type
              ),
              property_type: data?.property?.property_type ?? [
                data?.property?.type?.name,
              ],
            },
          }
          break
        }
        case "development": {
          data_form = {
            development_query: {
              status: true,
              name: data.nombre,
              phone: data.cellphone,
              email: data.email,
              message: data.text,
              address: data.development.address,
              url: data.url,
              development: data.development.name,
              property_id: data.development.id,
            },
          }
          break
        }
        case "services": {
          //     "appraisal": {
          //         "name": "test",
          //         "email": "test@test.com",
          //         "location": "calle - barrio - ciudad",
          //         "operation_type": "venta",
          //         "property_type": "casa",
          //         "rooms_amount": [1, 5, 3],
          //         "roofed_surface": 123,
          //         "total_surface": 321,
          //         "age": ["1 a 5 años", "Más de 50 años"],
          //         "garage": "SI/NO",
          //         "extra_amenities": ["Área de juegos", "Área coworking", "Gimnasio"],
          //   },
          //   nombre: "",
          //   email: "",
          //   ubicacion: "",
          //   sup_cub: "",
          //   sup_tot: "",
          //   rooms: "",
          //   garage: "",
          //   age: "",
          //   amenities: [],
          data_form = {
            appraisal: {
              status: true,
              name: data.nombre,
              email: data.email,
              location: data.ubicacion,
              operation_type: [data.operation],
              property_type: [data.type],
              rooms_amount: data.rooms,
              roofed_surface: data.sup_cub,
              total_surface: data.sup_tot,
              age: [data.age],
              garage: data.garage,
              extra_amenities: [data.amenities.join(", ")],
            },
          }
          break
        }
        case "newsletter": {
          data_form = {
            subscription: { status: true, email: data.email },
          }
          break
        }
        case "jobs": {
          data_form = {
            application: {
              status: true,
              email: data.email,
              gender: data.gender,
              date: data.date,
              location: data.location,
              phone: data.phone,
              cv: data.file,
            },
          }
          break
        }
      }
      const res = await axios.post(
        process.env.GATSBY_APISERVER +
          "/site/email/contact/?client=" +
          CLIENTID,
        data_form
      )
      console.log(res)
    } catch (e) {
      console.log(e)
    }
  }
